/**
 * Mdódulo para el manejo de importación de layout capítulo 1000.
 */
import Vue from 'vue'
import service from '@/api/service'
import Resource from '@/api/Resource'

//
// Ruta para subir el archivo excel "layout",
// que contiene información de sueldos y salarios
// para procesar, y generar proyección.
const IMPORTAR_SALARIOS = 'api/file-import-salarios'

//
// Ruta para generar proyeción de presupuesto,
// basado en el archivo layout excel que se procesa
// que contiene información de sueldos y salarios.
const GENERAR_PROYECCION = 'api/proyeccion-periodo'

// API para obtener cuántas ampliaciones se han planificado en la importación y proyección de capítulo 1000
const NUM_AMPLIACIONES = 'api/get-num-ampliaciones'

//
// Ruta para generar análisis de presupuesto
const ANALISIS_PRESUPUESTO = 'api/analisis-presupuesto'

const resource = new Resource('/api/layout-presupuesto', 'planeacionModule')

export default {
  namespaced: true,

  state: {
    periodo_fiscal: null,

    fuente_financiamiento: null,

    // fuente_financiamiento_id: null,

    // periodo_fiscal_id: null,

    /**
     * Variables específicamente para el proceso
     * de importación del layout.
     */
    importacion: {
      loading: false
    },

    /**
     * Variables que representan el estado de
     * proceso para la generación de la proyección.
     */
    proyeccion: {
      overwrite: true,
      response: [],
      loading: false,
      processed: false
    },

    analisis: {
      overwrite: true,
      response: [],
      loading: false,
      processed: false
    }
  },

  getters: {
    // hasPeriodoFiscal (state) {
    //   return Boolean(state.periodo_fiscal)
    // },

    // hasFuenteFinanciamiento (state) {
    //   return Boolean(state.fuente_financiamiento)
    // },

    // isImportacionFormValid (state) {
    //   return [
    //     //
    //     // Valores por defecto
    //     Boolean(state.importacion.periodo_fiscal_id),
    //     Boolean(state.importacion.fuente_financiamiento_id),
    //     //
    //     // Referente a la vista
    //     Boolean(state.importacion.file)
    //   ].every(item => item)
    // },

    isProyeccionFormValid (state) {
      return [
        Boolean(state.proyeccion.fuente_financiamiento_id),
        Boolean(state.proyeccion.periodo_fiscal_id)
      ].every(item => item)
    },

    getFieldsForProyeccion (state) {
      return state.proyeccion.response.length > 0 ? state.proyeccion.response[0] : []
    },

    getItemsForProyeccion (state) {
      return state.proyeccion.response.slice(1, state.proyeccion.response.length) || []
    }
  },

  mutations: {
    setPeriodoFiscal (state, payload) {
      state.periodo_fiscal = payload
    },

    // setPeriodoFiscalId (state, id) {
    //   state.periodo_fiscal_id = id
    // },

    // setFuenteFinanciamientoId (state, payload) {
    //   state.fuente_financiamiento_id = payload
    // },

    // Fix: delete
    setFuenteFinanciamiento (state, payload) {
      state.fuente_financiamiento = payload
    },

    setImportacion (state, { key, value }) {
      Vue.set(state.importacion, key, value)
    },

    setProyeccion (state, { key, value }) {
      Vue.set(state.proyeccion, key, value)
    },

    setAnalisis (state, { key, value }) {
      Vue.set(state.analisis, key, value)
    }
  },

  actions: {
    /**
     * Manda el layout al servidor.
     *
     * Manda el layout para ser procesado y posteriormente
     * poder generar una proyección de un periodo fiscal con
     * una fuente de financiamiento.
     */
    async uploadLayout ({ state, commit }, payload) {
      let retval = {}

      const formData = new FormData()

      formData.append('file', payload.file)
      //
      formData.append('periodo_fiscal_id', payload.periodo_fiscal_id)
      //
      formData.append('save_file', payload.save_file ? 1 : 0)
      //
      formData.append('overwrite', payload.overwrite ? 1 : 0)
      //
      formData.append('tipo', payload.tipo)
      //
      formData.append('num_ampliacion', payload.num_ampliacion)

      try {
        commit('setImportacion', { key: 'loading', value: true })

        const { data } = await service.post(IMPORTAR_SALARIOS, formData)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      } finally {
        commit('setImportacion', { key: 'loading', value: false })
      }

      return retval
    },

    async getNumAmpliacionList ({ state }, payload) {
      const { data } = await service.get(`${NUM_AMPLIACIONES}?periodo_fiscal_id=${payload.periodo_fiscal_id}`)
      return data
    },

    /**
     * Solicita al servidor realizar una proyección.
     *
     * Basado en un periodo fiscal con una fuente de financiamiento
     * en específico.
     *
     */
    async generarProyeccion ({ state, commit }, payload) {
      let retval = {}

      try {
        commit('setProyeccion', { key: 'loading', value: true })

        const { data } = await service.post(GENERAR_PROYECCION, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      } finally {
        commit('setProyeccion', { key: 'loading', value: false })
      }

      return retval
    },

    /**
     * Obtiene la proyección en base al periodo solicitado.
     */
    async getProyeccionPeriodo ({ commit }, payload) {
      const { error, data, message } = await resource.all(payload)

      if (error) return { error, message }

      commit('setProyeccion', { key: 'response', value: data.data })

      return { data, message }
    },

    async getProyeccionPeriodoByMes ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await resource.all(payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async crearImporte ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await resource.create(payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async editarProyeccion ({ commit, dispatch }, { id, payload }) {
      let retval = {}

      try {
        const { data } = await resource.update(id, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async eliminarProyeccion ({ dispatch }, id) {
      let retval = {}

      try {
        const { data } = await resource.delete(id)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getAnalisisBy ({ state }, { periodo_fiscal_id, tipo, num_ampliacion }) {
      let retval = {}

      try {
        const { data } = await service.get(ANALISIS_PRESUPUESTO, {
          params: {
            periodo_fiscal_id,
            tipo,
            num_ampliacion
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * Correr algoritmo para generar análisis de presupuesto
     * @post
     */
    async generarAnalisis ({ state }, { periodo_fiscal_id, fuente_financiamiento_id, tipo, num_ampliacion, fuente_estatal_id, fuente_ingresos_propios_id }) {
      let retval = {}

      try {
        const payload = {
          periodo_fiscal_id,
          fuente_financiamiento_id,
          tipo,
          num_ampliacion,
          fuente_estatal_id,
          fuente_ingresos_propios_id,
          overwrite: state.analisis.overwrite
        }
        const { data } = await service.post(ANALISIS_PRESUPUESTO, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * DEPRECATED: updateAnalisisImport
     */
    async updateAnalisisImport ({ state }, payload) {
      let retval = {}

      try {
        //
        // Adiciona los datos por defecto
        payload.periodo_fiscal_id = state.periodo_fiscal.id
        payload.fuente_financiamiento_id = state.fuente_financiamiento.id
        payload._method = 'PUT'

        const { data } = await service.put(ANALISIS_PRESUPUESTO, payload)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    /**
     * autorizar.
     *
     * Autoriza el presupuesto. No recibe parámetros pero toma por defecto el periodo fiscal
     * actualmente activo para hacer la consulta al backend.
     */
    async autorizar ({ dispatch }) {
      let retval = {}

      try {
        const p = await dispatch('fiscalPeriodsModule/getActiveFiscalPeriod', {}, { root: true })

        const { data } = await service.post('api/analisis-presupuesto/autorizar', {
          periodo_fiscal_id: p.id
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async exportReports ({ context }, payload) {
      let retval = {}

      let endpoint = ''

      try {
        switch (payload.name) {
          case 'proyectos':
            endpoint = 'analisis-presupuesto/export-proyectos'
            break

          case 'unidades':
            endpoint = 'analisis-presupuesto/export-unidades'
            break

          case 'cuadernos':
            endpoint = 'analisis-presupuesto/export-cuaderno-trabajo'
            break

          case 'layout':
            endpoint = 'analisis-presupuesto/export-layout-saacgnet'
            break

          default:
            break
        }

        const { data } = await service({
          _method: 'GET',
          url: `api/${endpoint}`,
          responseType: 'blob',
          params: {
            fuente_financiamiento_id: payload.fuente_financiamiento_id,
            periodo_fiscal_id: payload.periodo_fiscal_id,
            tipo: payload.tipo,
            num_ampliacion: payload.num_ampliacion
          }
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getAnalisisPresupuestoDesglose ({ context }, payload) {
      let retval = {}

      try {
        const { data } = await service.get('api/analisis-presupuesto/desglose-partida', {
          params: payload
        })

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async updateAnalisisPresupuestoDesgloseItem ({ context }, params) {
      let retval = {}

      try {
        const { data } = await service.put('api/analisis-presupuesto/desglose-partida', params)

        retval = data
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
